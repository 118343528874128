<template>
  <div class="images">
    <marketing-materials-tour v-if="$store.state.tour & !loading" />

    <transition name="menu-popover">
      <div v-if="lightboxImage" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <!-- need eula in lightbox -->

        <!--
				<h2 v-if="showCopy" class="downloadImage">
					<span
						class=""
						@click="showEula(lightboxImage, 'download')"
					>
						<i 
						class="fa fa-download" 
						aria-hidden="true"
						data-toogle="tooltip"
						title="Download a compressed version of this image"></i>
					</span>
				</h2>
				-->

        <img :src="lightboxImage" @click="closeLightbox()" />
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo"></div>
        <!-- <img :src="lightboxImage" @click="closeLightbox()"/> -->
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxConfidential" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()" style="top: 0">
          <a>&#215;</a>
        </h2>

        <iframe
          width="90%"
          height="800"
          :src="lightboxConfidential"
          frameborder="0"
          allowfullscreen
          @click="closeLightbox()"
        ></iframe>
      </div>
    </transition>

    <section
      data-v-5a90ec03
      class="pt-5 pb-3 bg8 bs-shadow mobile-top"
    >
      <div id="mm-step-1" class="container">
        <div class="input-group mb-3 bs-large">
          <input
            type="text"
            class="top-search"
            placeholder="Search for marketing media in all categories"
            v-model="searchValue"
            @keyup.enter="searchQuery"
            style="padding: 20px!important;"
          />
        </div>
        <div class="search-btns">
          <div class="left-side">
            <h6
              v-if="search.length && search != 'Products/'"
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Marketing Materials > {{ catClean(search) }}
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
              {{ fileType == "JPEG" ? " > Images" : "" }}
              {{ catType != "" ? " > " + catClean(catType) : "" }}
            </h6>
            <h6
              v-else-if="searchValue.length"
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Marketing Materials > Searching > All Categories
            </h6>
            <h6
              v-else
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Marketing Materials > All Categories
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
              {{ fileType == "JPEG" ? " > Images" : "" }}
              {{ catType != "" ? " > " + catClean(catType) : "" }}
            </h6>
          </div>
          <div class="right-side">
            <button
              v-if="searchValue.length"
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>

            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button
              v-if="
                searchValue.length ||
                this.search != 'Products/' ||
                this.catType != '' ||
                this.fileType !=
                  'PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)'
              "
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- scroll target -->
    <div id="pag"></div>

    <div :class="loading == false && !media.length ? 'message-bg' : ''">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12">
            <!--
            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayFiles" class="d-flex droplink">
                <h5 id="mm-step-2" style="margin-bottom: 0">Files</h5>
                <span
                  class="material-icons mlauto"
                  :class="showFiles == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showFiles">
                <ul class="flexwrap mt10">
                  <transition-group tag="ul" name="list" class="flexwrap">
                    <li
                      v-for="all in allMedia"
                      :key="all.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{
                          active:
                            fileType ===
                            'PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)',
                        }"
                        @click="changeFile(all.search, all.title)"
                      >
                        {{ all.title }}
                      </button>
                    </li>

                    <li
                      v-for="file in fileTypes"
                      :key="file.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: file.search == fileType }"
                        @click="changeFile(file.search, file.title)"
                      >
                        {{ file.title }}
                      </button>
                    </li>
                  </transition-group>
                </ul>

                <h6 @click="displayTypes" class="mt20 mb0 vert-center droplink">
                  <span
                    class="material-icons"
                    :class="showTypes == true ? 'active-tri' : 'inactive-tri'"
                    >sort</span
                  >
                  Types
                </h6>
                
                <div v-if="showTypes">
                  <hr />
                  <ul class="flexwrap">
                    <li v-for="file in Types" :key="file.title" class="mktBtns">
                      <button
                        class="btn-primary"
                        :class="{ active: file.search == catType }"
                        @click="changeCatType(file.search, file.title)"
                      >
                        {{ file.title }}
                      </button>
                    </li>
                    <li class="mktBtns">
                      <a
                        class="btn-primary"
                        style="
                          text-decoration: none;
                          position: relative;
                          display: block;
                        "
                        href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                        target="_blank"
                        rel="noopener nooreferrer"
                      >
                        MSDS
                      </a>
                    </li>
                  </ul>
                </div>
              
              </div>
            </div>
            -->

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayMobile" class="d-flex droplink">
                <h5 id="mm-step-4" style="margin-bottom: 0">Mobile</h5>
                <span
                  class="material-icons mlauto"
                  :class="showMobile == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showMobile">
                <h6
                  @click="displayPowered"
                  class="mt20 mb10 vert-center droplink"
                  style="margin-bottom: 10px"
                >
                  <span
                    class="material-icons"
                    :class="showPowered == true ? 'active-tri' : 'inactive-tri'"
                    >sort</span
                  >
                  Powered
                </h6>

                <div v-if="showPowered">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in products[0].cats[0].cats"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changeMarket(subcat.search, subcat.title)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>

                <h6
                  @click="displayMechassist"
                  class="mt20 mb10 vert-center droplink"
                  style="margin-bottom: 10px"
                >
                  <span
                    class="material-icons"
                    :class="
                      showMechassist == true ? 'active-tri' : 'inactive-tri'
                    "
                    >sort</span
                  >
                  Mechanical Assist
                </h6>

                <div v-if="showMechassist">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in products[0].cats[1].cats"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changeMarket(subcat.search, subcat.title)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>
                <h6
                  @click="displayManual"
                  class="mt20 mb10 d-flex vert-center droplink"
                  style="margin-bottom: 10px"
                >
                  <span
                    class="material-icons"
                    :class="showManual == true ? 'active-tri' : 'inactive-tri'"
                    >sort</span
                  >
                  Manual
                </h6>

                <div v-if="showManual">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in products[0].cats[2].cats"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changeMarket(subcat.search, subcat.title)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>
                 <h6
                  @click="displayArtRacks"
                  class="mt20 mb10 d-flex vert-center droplink"
                  style="margin-bottom: 10px"
                >
                  <span
                    class="material-icons"
                    :class="showArtRacks == true ? 'active-tri' : 'inactive-tri'"
                    >sort</span
                  >
                  Art Racks
                </h6>

                <div v-if="showArtRacks">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in products[0].cats[3].cats"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changeMarket(subcat.search, subcat.title)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayShelving" class="d-flex droplink">
                <h5 id="mm-step-3" style="margin-bottom: 0">Shelving</h5>
                <span
                  class="material-icons mlauto"
                  :class="showShelving == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showShelving">
                <ul class="flexwrap mt10">
                  <li
                    v-for="subcat in products[1].cats"
                    :key="subcat.title"
                    class="mktBtns"
                  >
                    <button
                      class="btn-primary"
                      :class="{ active: subcat.search == search }"
                      @click="changeMarket(subcat.search, subcat.title)"
                    >
                      {{ subcat.title }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="card-block bs-shadow"
              style="margin-top: 15px; margin-bottom: 20px"
            >
              <div @click="displayStorage" class="d-flex droplink">
                <h5 id="mm-step-3" style="margin-bottom: 0">Storage</h5>
                <span
                  class="material-icons mlauto"
                  :class="showStorage == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showStorage">
                <ul class="flexwrap mt10">
                  <li
                    v-for="subcat in products[2].cats"
                    :key="subcat.title"
                    class="mktBtns"
                  >
                    <button
                      class="btn-primary"
                      :class="{ active: subcat.search == search }"
                      @click="changeMarket(subcat.search, subcat.title)"
                    >
                      {{ subcat.title }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayMarkets" class="d-flex droplink">
                <h5 id="mm-step-3" style="margin-bottom: 0">Markets</h5>
                <span
                  class="material-icons mlauto"
                  :class="showMarkets == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <ul v-if="showMarkets" class="flexwrap mt10">
                <li
                  v-for="market in markets"
                  :key="market.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: market.search == search }"
                    @click="changeMarket(market.search, market.title)"
                  >
                    {{ market.title }}
                  </button>
                </li>
              </ul>
            </div>


            <div
              class="card-block bs-shadow"
              style="margin-top: 15px; margin-bottom: 20px"
            >
              <div @click="displayPriceLists" class="d-flex droplink">
                <h5 style="margin-bottom: 0">Sustainability</h5>
                <span
                  :class="
                    showPriceLists == true ? 'active-tri' : 'inactive-tri'
                  "
                  class="material-icons mlauto"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showPriceLists" class="mb0 mt10">
                <li
                  v-for="link in software"
                  :key="link.name"
                  class="d-block softwareli"
                >
                  <div class="d-flex">
                    <span class="lh12 software-link">
                      <a :href="link.link" target="_blank">{{
                        link.name
                      }}</a></span
                    >
                    <span class="ml-auto d-flex software">
                      <a
                        class="links mr5"
                        v-clipboard="link.link"
                        v-on:click="copyLink"
                        ><i
                          class="material-icons"
                          data-toogle="tooltip"
                          title="Copy this file location"
                          >file_copy</i
                        ></a
                      >
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- end desktop filters -->

          <div
            v-if="loading == true"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0"><small>Searching</small></p>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="sortView" disabled>
                  <font-awesome-icon
                    icon="sort-alpha-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-alpha-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="dateView" disabled>
                  <font-awesome-icon
                    icon="sort-amount-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-amount-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="listView == false" disabled>
                  <font-awesome-icon icon="list" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
                <button v-else disabled>
                  <font-awesome-icon icon="th" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
              </div>

              <div>
                <div id="mm-step-7" class="nav-btns">
                  <button disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <ul class="flexwrap">
                <li v-for="file in fileTypes" :key="file.title" class="mktBtns">


                  <button
                    class="btn-primary"
                    :class="{ active: file.search == fileType }"
                    @click="changeFile(file.search, file.title)"
                  >
                    {{ file.title }}
                  </button>


                </li>
                <li v-for="file in Types" :key="file.title" class="mktBtns">


                  <button
                    class="btn-primary"
                    :class="{ active: file.search == catType }"
                  >
                    {{ file.title }}
                  </button>

                  
                </li>
                <!--
                <li class="mktBtns">
                  <a
                    class="btn-primary"
                    style="
                      text-decoration: none;
                      position: relative;
                      display: block;
                    "
                    href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                    target="_blank"
                    rel="noopener nooreferrer"
                  >
                    MSDS
                  </a>
                </li>
                -->
              </ul>
            </div>
            <div style="margin-top: 100px">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />

                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !media.length"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0"><small>Searching</small></p>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="sortView" disabled>
                  <font-awesome-icon
                    icon="sort-alpha-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-alpha-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="dateView" disabled>
                  <font-awesome-icon
                    icon="sort-amount-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
                <button v-else disabled>
                  <font-awesome-icon
                    icon="sort-amount-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button v-if="listView == false" disabled>
                  <font-awesome-icon icon="list" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
                <button v-else disabled>
                  <font-awesome-icon icon="th" style="margin-right: 5px" />
                  {{ " " }}View
                </button>
              </div>

              <div>
                <div id="mm-step-7" class="nav-btns">
                  <button disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <ul class="flexwrap">
                <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{ active: file.search == fileType }"
                    @click="changeFile(file.search, file.title)"
                  >
                    {{ file.title }}
                  </button>
                </li>
                <li v-for="file in Types" :key="file.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{ active: file.search == catType }"
                    @click="changeCatType(file.search, file.title)"
                  >
                    {{ file.title }}
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
                Sorry, I coudn't find the media that you requested...
              </h2>
              <img src="@/assets/img/userAction.svg" style="width: 100%" />
            </div>
          </div>

          <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-8">
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  {{ this.media.length + " " }}<small>Results</small>
                </p>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button
                  v-if="sortView"
                  @click="sortSwitch"
                  style="cursor: pointer"
                >
                  <font-awesome-icon
                    icon="sort-alpha-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
                <button
                  v-if="!sortView"
                  @click="sortSwitch"
                  style="cursor: pointer"
                >
                  <font-awesome-icon
                    icon="sort-alpha-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Sort
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <button
                  v-if="dateView"
                  @click="dateSwitch"
                  style="cursor: pointer"
                >
                  <font-awesome-icon
                    icon="sort-amount-up"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
                <button v-else @click="dateSwitch" style="cursor: pointer">
                  <font-awesome-icon
                    icon="sort-amount-down"
                    style="margin-right: 5px"
                  />
                  {{ " " }}Date
                </button>
              </div>

              <div class="view-switch switch-desktop hide-mobile">
                <!--
                <button
                  v-if="!listView"
                  @click="viewSwitch"
                  style="cursor: pointer"
                >
                  <i class="fas fa-list" style="margin-right: 5px"></i
                  >{{ " " }}View
                </button>
                -->
                <button
                  v-if="listView == false"
                  @click="viewSwitch"
                  style="cursor: pointer"
                >
                  <font-awesome-icon icon="list" style="margin-right: 5px" />{{
                    " "
                  }}View
                </button>
                <button v-else @click="viewSwitch" style="cursor: pointer">
                  <font-awesome-icon icon="th" style="margin-right: 5px" />{{
                    " "
                  }}View
                </button>
              </div>

              <div>
                <div id="mm-step-7" class="nav-btns">
                  <button v-if="this.offsetVal == 0" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>
                  <button v-else @click="offsetValMinus()">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.media.length < 99" disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                  <button v-else @click="offsetValPlus()">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="
                banner
                d-flex
                align-items-center
                p-3
                my-3
                rounded
                bs-shadow
              "
            >
              <ul class="flexwrap">
                <!--
                <li v-for="all in allMediaNew" :key="all.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{
                      active:
                        fileType ===
                        'PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)',
                    }"
                    @click="changeFile(all.search, all.title)"
                  >
                    {{ all.title }}
                  </button>
                </li>
                -->

                <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{ active: file.search == fileType }"
                    @click="changeFile(file.search, file.title)"
                  >
                    {{ file.title }}
                  </button>
                </li>
                <li v-for="file in Types" :key="file.title" class="mktBtns">
                  <button
                    class="btn-primary"
                    :class="{ active: file.search == catType }"
                    @click="changeCatType(file.search, file.title)"
                  >
                    {{ file.title }}
                  </button>
                </li>
                <!--
                <li class="mktBtns">
                  <a
                    class="btn-primary"
                    style="
                      text-decoration: none;
                      position: relative;
                      display: block;
                    "
                    href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                    target="_blank"
                    rel="noopener nooreferrer"
                  >
                    MSDS
                  </a>
                </li>
                -->
              </ul>
            </div>

            <div>
              <!-- ###################### if grid view ######################### -->

              <div class="row" v-if="listView == false">
                <div
                  v-for="(el, index) in media"
                  :key="el.external_id"
                  class="col-md-4 col col-sm-6 col-xs-12 col-12"
                >
                  <div class="card bs-shadow br4">
                    <div
                      :class="
                        el.file_properties['format_type'] ==
                        'compressed_archive'
                          ? 'zip'
                          : null
                      "
                      class="img-responsive-4by3"
                      v-bind:style="{
                      backgroundImage: 'url(' + (el.embeds && el.embeds.video_poster && el.embeds.video_poster.url ? el.embeds.video_poster.url : el.thumbnails['600px'].url) + ')'
                      }"
                      style="background-size: cover"
                    >
                      <span
                        :id="index === 0 ? 'mm-step-6' : ''"
                        class="file-icon"
                        v-if="!listView"
                      >
                        <i
                          v-if="el.file_properties['format_type'] == 'pdf'"
                          class="fas fa-file-pdf"
                        ></i>
                        <!--
                        <i
                          v-if="
                            el.file_properties['format_type'] == 'image' &&
                            el.file_properties['format'] == 'GIF' &&
                            !listView
                          "
                          class="fas fa-film"
                        ></i>
                        -->
                        <i
                          v-else-if="
                            el.file_properties['format_type'] == 'image'
                          "
                          class="far fa-image"
                        ></i>
                        <i
                          v-else-if="
                            el.file_properties['format_type'] == 'video'
                          "
                          class="fas fa-video"
                        ></i>
                        <i
                          v-else-if="
                            el.file_properties['format_type'] == 'office'
                          "
                          class="fas fa-file"
                        ></i>
                        <i
                          v-else-if="
                            el.file_properties['format_type'] ==
                            'compressed_archive'
                          "
                          class="fas fa-file-archive"
                        ></i>
                      </span>
                    </div>

                    <div :id="index === 0 ? 'mm-step-5' : ''" class="card-name">
                      <p class="media-title" style="margin-bottom: 5px">
                        <span
                          v-if="
                            el.security.asset_groups.includes(
                              'Confidential Documents'
                            )
                          "
                          class="badge badge-orange"
                          style="margin-right: 5px; font-size: inherit"
                          >CONFIDENTIAL</span
                        >
                        {{ el.filename }}
                      </p>

                      <span
                        class="button-wrap"
                        v-if="
                          el.file_properties['format_type'] == 'image' &&
                          el.file_properties['format'] == 'GIF'
                        "
                        ><!-- if it's a GIF -->
                        <a
                          class="first links"
                          v-on:click="
                            showEula(
                              el.embeds['asset_stream_link'].url,
                              'download'
                            )
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this image"
                            >visibility</i
                          >
                        </a>

                        <span
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5 center"
                          @click="
                            showEula(el.embeds['original'].url, 'download')
                          "
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this full-size image"
                          >
                            sim_card_download</i
                          >
                        </span>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <span
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          @click="showEula(el.embeds['original'].share, 'mail')"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </span>
                      </span>

                      <span
                        class="button-wrap"
                        v-if="
                          el.file_properties['format_type'] == 'image' &&
                          el.file_properties['format'] != 'GIF'
                        "
                        ><!-- if it's an image -->
                        <a
                          class="first links"
                          v-on:click="showLightbox(el.thumbnails['600px'].url)"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this image"
                            >visibility</i
                          >
                        </a>

                        <span
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5 center"
                          @click="
                            showEula(el.embeds['original'].url, 'download')
                          "
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this full-size image"
                          >
                            sim_card_download</i
                          >
                        </span>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <span
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          @click="showEula(el.embeds['original'].share, 'mail')"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </span>
                      </span>

                      <span
                        class="button-wrap"
                        v-if="
                          (el.file_properties['format_type'] == 'pdf') |
                            (el.file_properties['format_type'] == 'office')
                        "
                        ><!-- if it's a document -->
                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            )
                          "
                          @click="
                            showEula(
                              el.embeds['document_viewer_with_download'].url,
                              'download'
                            )
                          "
                          class="first links"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this PDF"
                            >visibility</i
                          >
                        </a>

                        <a
                          v-else
                          class="first links"
                          @click="
                            showLightboxConf(
                              el.embeds['document_html5_viewer'].url,
                              'confdoc'
                            )
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this PDF"
                            >visibility</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5 center"
                          :href="el.embeds['original'].share"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this Document"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </span>

                      <span
                        class="button-wrap"
                        v-if="el.file_properties['format_type'] == 'video'"
                        ><!-- It's a video -->
                        <span
                          class="first links"
                          v-on:click="
                            showLightboxVideo(el.embeds['video_player'].html)
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this video"
                            >visibility</i
                          >
                        </span>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['video_player'].url"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <span
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          @click="
                            showEula(el.embeds['video_player'].url, 'mail')
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </span>
                      </span>

                      <span
                        class="button-wrap"
                        :class="
                          el.security.asset_groups.includes(
                            'Confidential Documents'
                          )
                            ? 'mb5'
                            : null
                        "
                        v-if="
                          el.file_properties['format_type'] ==
                          'compressed_archive'
                        "
                        style="
                          -webkit-align-content: flex-end;
                          align-content: flex-end;
                        "
                        ><!-- if it's a zip -->
                        <a
                          v-if="!showCopy"
                          @click="
                            showEula(el.embeds['original'].share, 'download')
                          "
                          class="links ml-auto mr5 center"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this zip archive"
                          >
                            sim_card_download</i
                          >
                        </a>
                        <a
                          v-else
                          class="links ml-auto mr5 center"
                          :href="el.embeds['original'].share"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this zip archive"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </span>
                    </div>
                  </div>
                  <!-- card -->
                </div>
              </div>

              <div class="row" v-else-if="listView">
                <!-- #################### if list view #################### -->
                <div
                  v-for="(el, index) in media"
                  :key="el.external_id"
                  class="col-xs-12 col-12 list-view"
                >
                  <div
                    class="card bs-shadow br4 mb8"
                    style="margin-bottom: 8px"
                  >
                    <div
                      :id="index === 0 ? 'st-step-5' : ''"
                      class="card-name"
                      style="display: flex; padding: 10px"
                      v-if="listView"
                    >
                      <p class="mrauto mb0" style="max-width: 70%">
                        <span
                          v-if="
                            el.security.asset_groups.includes(
                              'Confidential Documents'
                            )
                          "
                          class="badge badge-orange"
                          style="margin-right: 5px; font-size: inherit"
                          >CONFIDENTIAL</span
                        ><span>{{ el.filename }}</span>
                      </p>

                      <!--- ################# start issue solved Virtual dom injecting spans, changed all spans to divs #################### -->
                      <div
                        class="button-wrap"
                        v-if="
                          el.file_properties['format_type'] == 'image' &&
                          listView
                        "
                      >
                        <a
                          class="links mr5"
                          v-on:click="showLightbox(el.thumbnails['600px'].url)"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this image"
                            >visibility</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) &&
                            showCopy &&
                            el.file_properties['format_type'] == 'image' &&
                            listView
                          "
                          class="links mr5"
                          :href="el.embeds['original'].url"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this image"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) &&
                            showCopy &&
                            el.file_properties['format_type'] == 'image' &&
                            listView
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) &&
                            showCopy &&
                            el.file_properties['format_type'] == 'image' &&
                            listView
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </div>

                      <div
                        class="button-wrap"
                        v-else-if="
                          (el.file_properties['format_type'] == 'pdf') |
                            (el.file_properties['format_type'] == 'office')
                        "
                      >
                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            )
                          "
                          @click="
                            showEula(
                              el.embeds['document_viewer_with_download'].url,
                              'download'
                            )
                          "
                          target="_blank"
                          class="mr5 links"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this PDF"
                            >visibility</i
                          >
                        </a>
                        <a
                          v-else
                          class="mr5 links"
                          @click="
                            showLightboxConf(
                              el.embeds['document_html5_viewer'].url
                            )
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this PDF"
                            >visibility</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          :href="el.embeds['original'].share"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this Document"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </div>

                      <div
                        class="button-wrap"
                        v-else-if="el.file_properties['format_type'] == 'video'"
                      >
                        <a
                          class="links mr5"
                          v-on:click="
                            showLightboxVideo(el.embeds['video_player'].html)
                          "
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Preview this video"
                            >visibility</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['video_player'].url"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['video_player'].url}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </div>

                      <div
                        class="button-wrap"
                        v-else-if="
                          el.file_properties['format_type'] ==
                          'compressed_archive'
                        "
                        style="margin-bottom: 5px"
                      >
                        <a
                          v-if="!showCopy"
                          @click="
                            showEula(el.embeds['original'].share, 'download')
                          "
                          class="links ml-auto mr5 center"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this zip archive"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-else
                          class="links mr5 ml-auto center"
                          :href="el.embeds['original'].share"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            class="material-icons"
                            aria-hidden="true"
                            data-toogle="tooltip"
                            title="Download this zip archive"
                          >
                            sim_card_download</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links mr5"
                          v-clipboard="el.embeds['original'].share"
                          v-on:click="copyLink"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Copy this file location"
                            >file_copy</i
                          >
                        </a>

                        <a
                          v-if="
                            !el.security.asset_groups.includes(
                              'Confidential Documents'
                            ) && showCopy
                          "
                          class="links"
                          :href="`mailto:?subject=Link&body=${el.embeds['original'].share}`"
                        >
                          <i
                            class="material-icons"
                            data-toogle="tooltip"
                            title="Email this file"
                            >send</i
                          >
                        </a>
                      </div>
                      <!--- ################# end issue solved Virtual dom injecting spans, changed all spans to divs #################### -->
                    </div>
                  </div>
                  <!-- card -->
                </div>
              </div>
            </div>

            <!-- bottom pagination -->
            <div
              v-if="media.length >= 4"
              class="
                banner
                d-flex
                align-items-center
                p-3
                mb1r
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  {{ this.media.length + " " }}<small>Results</small>
                </p>
              </div>

              <div>
                <div class="nav-btns">
                  <button v-if="this.offsetVal == 0" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>
                  <button v-else @click="offsetValMinus()">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.media.length < 100" disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                  <button v-else @click="offsetValPlus()">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <!-- end bottom pagination -->
          </div>
          <!-- end other column -->
        </div>
      </div>
    </div>
    <!--
    <div class="menu-box bounce">
      <i class="material-icons">menu_open</i>
    </div>
    -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Eula from "../shared/Eula.js";
import MarketingMaterialsTour from "../components/Tours/MarketingMaterialsTour";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/a59d39c4db9c288752c13e9c1196c9cf",
  },
};

export default {
  name: "Media",
  components: {
    AtomSpinner,
    MarketingMaterialsTour,
  },
  data() {
    return {
      pageSlug: "",
      msg: "",
      offsetVal: 0,
      search: this.$route.query.cat ? this.$route.query.cat : "Products/",
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      media: [],
      loading: true,
      imagesInPresentation: [],
      selectedImage: "",
      selectedVid: null,
      fileType:
        "PDF}%20or%20{GIF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
      catType: this.$route.query.type ? this.$route.query.type : "",
      type: "",
      lightboxImage: "",
      lightboxVideo: "",
      lightboxConfidential: "",
      allMedia: [
        {
          title: "All Media",
          search:
            "PDF}%20or%20{GIF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
          active: false,
        },
      ],
      Types: [
        //{ title: "Ad", search: "/Advertisement", active: false },
        { title: "Brochure", search: "/Brochure", active: false },
        { title: "Case Study", search: "/Case Study", active: false },
        { title: "Design Finishes", search: "/Design Finishes", active: false },
        //{ title: "Diagrams", search: "/Wire Diagram", active: false },
        { title: "Info Sheet", search: "/Info Sheet", active: false },
        /*{
          title: "Install",
          search: "/Installation Manual",
          active: false,
        },*/
        { title: "Owner's Manual", search: "/Owner's Manual", active: false },
        { title: "Planning Guide", search: "/Planning Guide", active: false },
        { title: "Product News", search: "/Product News", active: false },
        { title: "Webinar", search: "/Webinar", active: false },
        { title: "Specs", search: "/Specs", active: false },
        { title: "Tech Data", search: "/Tech Data", active: false },

        //{ title: "All", search: "", active: false },
        //{ title: "Training", search: "/Training", active: false },
      ],
      images: [
        { id: 1, name: "http://www.google.com/image1" },
        { id: 2, name: "ttp://www.google.com/image2" },
        { id: 3, name: "ttp://www.google.com/image3" },
      ],
      showFiles: true,
      showMarkets: true,
      showProducts: false,
      showMobile: true,
      showShelving: true,
      showStorage: true,
      showPowered: true,
      showMechassist: true,
      showManual: true,
      showArtRacks: true,
      showTypes: true,
      eulaAccepted: false,
      selected: [],
      listView: false,
      sortView: false,
      dateView: false,
      showPriceLists: true,
      software: [
        {
          name: "EPD",
          link: "https://spacesaver.widencollective.com/c/7aqmvmnn",
        },
        {
          name: "HPD",
          link: "https://spacesaver.widencollective.com/c/tshxmwdx",
        },
        {
          name: "All",
          link: "https://spacesaver.widencollective.com/c/9xlow8eh",
        },
      ],
      allMedia: [
        {
          title: "All Media",
          search:
            "PDF}%20or%20{GIF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
          active: false,
        },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "media");

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        let view = localStorage.getItem("SSCview");
        if (view == "list") {
          this.listView = true;
        } else {
          this.listView = false;
        }
        this.loading = false;
      });
    },
    catClean: function (cat) {
      console.log(cat);
      //remove everything before the last /
      let a = cat.replace(/%20/g, " ").split("/").pop();
      return a;
    },
    displayPriceLists() {
      this.showPriceLists = !this.showPriceLists;
    },
    viewSwitch: function () {
      this.listView = !this.listView;
      if (this.listView) {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "list");
      } else {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "grid");
      }
    },
    sortSwitch: function () {
      if (this.sortView == false) {
        this.media.sort((a, b) => (a.filename > b.filename ? 1 : -1));
      } else {
        this.media.sort((a, b) => (a.filename < b.filename ? 1 : -1));
      }
      this.sortView = !this.sortView;
    },
    dateSwitch: function () {
      if (this.dateView == false) {
        this.media.sort((a, b) =>
          a.last_update_date > b.last_update_date ? 1 : -1
        );
      } else {
        this.media.sort((a, b) =>
          a.last_update_date < b.last_update_date ? 1 : -1
        );
      }
      this.dateView = !this.dateView;
    },
    copyLink: function () {
      this.$noty.success("Successfully copied link to clipboard!");
    },

    copyData(link) {
      this.makeData();
      this.$clipboard(this.invite_code);
      alert("Copied to clipboard");
    },

    addImageToPresentation(name) {
      let image = {
        thumb: name,
        large: "large image",
      };
      this.$store.commit("addToImages", image);
      this.$noty.success("Successfully added image to presentation!");
      //this.$noty.error("Oooops! Something went wrong");
    },

    offsetValMinus() {
      this.offsetVal -= 100;
      this.loading = true;

      if (this.searchValue) {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    offsetValPlus() {
      this.offsetVal += 100;
      this.loading = true;

      if (this.searchValue) {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    changeFile(name) {
      /*
				# Can Change to All, images, Doc, Video (this.fileType = name;)
				# Can have market (search)
				# can’t have catType (this.catType = "";)
			*/

      this.offsetVal = 0;
      this.loading = true;
      this.fileType = name;
      this.catType = "";

      if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        // all media and a search value
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&file=document`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        // all media and a search value
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}`
        );
      } else if (this.fileType == "MPEG4" && this.searchValue.length) {
        //search value and video
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&file=video`
        );
      } else if (this.fileType == "JPEG" && this.searchValue.length) {
        //search value and jpeg
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&file=image`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length &&
        this.search != "Products/"
      ) {
        // all media and cat selected
        history.pushState({}, "", `/marketing-materials?cat=${this.search}`);
      } else if (
        this.fileType ==
          "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        // all and search typed in
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG" && this.search.length) {
        //category and jpeg selected
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&file=image`
        );
      } else if (this.fileType == "MPEG4" && this.search.length) {
        //category and video
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&file=video`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&file=document`
        );
      } else {
        history.pushState({}, "", `/marketing-materials`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    changeCatType(name) {
      /*
				# Can Change to All, Webinar etc. this.catType = name;
				# Can have market (search)
				# can’t have fileType (this.fileType)
			*/
      this.offsetVal = 0;
      this.loading = true;
      this.catType = name;
      this.fileType =
        "PDF}%20or%20{GIF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";

      // has cat type filter, search bar is filled in and searching products
      if (
        this.catType.length &&
        this.searchValue.length &&
        this.search == "Products/"
      ) {
        console.log(
          "has category filter, search bar filled in, searching products"
        );

        //history.pushState({}, '', `/marketing-materials?cat=${this.search}&type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&type=${this.catType}`
        );
        //https://api.widencollective.com/v2/assets/search?query=ff%3A({PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)})%20eclipse&limit=100&&offset=0&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity
      } else if (
        this.catType.length &&
        this.search.length &&
        this.search != "Products/"
      ) {
        //history.pushState({}, '', `/marketing-materials?type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&type=${this.catType}`
        );
      } else if (
        !this.catType.length &&
        this.search.length &&
        this.search != "Products/"
      ) {
        history.pushState({}, "", `/marketing-materials?cat=${this.search}`);
      } else {
        history.pushState({}, "", `/marketing-materials`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    changeMarket(name, title) {
      /*
				#NOT DONE
			 	# can change Markets, products, sub-categories (this.search = name;)
				# Can have market (this.search = name;)
				# can have fileType	(this.fileType)	
				# can have catType	(this.catType)		
			*/

      this.selected = [];
      this.selected.push(title);
      this.offsetVal = 0;
      this.loading = true;
      this.searchValue = "";
      this.search = name;

      if (
        this.fileType ==
          "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length &&
        this.search != "Products/"
      ) {
        // all media nothing selected
        if (this.catType.length) {
          history.pushState(
            {},
            "",
            `/marketing-materials?cat=${this.search}&type=${this.catType}`
          );
        } else {
          history.pushState({}, "", `/marketing-materials?cat=${this.search}`);
        }
      } else if (
        this.fileType == "JPEG" &&
        this.search.length &&
        this.search != "Products/"
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&file=image`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length &&
        this.search != "Products/"
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&type=document`
        );
      } else if (
        this.fileType == "MPEG4" &&
        this.search.length &&
        this.search != "Products/"
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&type=video`
        );
      } else if (
        this.catType.length &&
        this.search.length &&
        this.search != "Products/"
      ) {
        //history.pushState({}, '', `/marketing-materials?type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/marketing-materials?cat=${this.search}&type=${this.catType}`
        );
      } else {
        history.pushState({}, "", `/marketing-materials`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    clearSearch: function () {
      //this.searchStarted = false;
      this.searchValue = "";
      this.search = "Products/";
      this.fileType =
        "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
      this.catType = "";
      this.loading = true;
      history.pushState({}, "", `/marketing-materials`);

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    searchQuery(search) {
      this.search = "Products/";
      this.type = "";
      this.catType = "";
      this.offsetVal = 0;
      this.loading = true;

      if (
        this.fileType ==
        "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG") {
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&type=image`
        );
      } else if (this.fileType == "MPEG4") {
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&type=video`
        );
      } else if (
        this.fileType ==
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}&type=document`
        );
      } else {
        history.pushState(
          {},
          "",
          `/marketing-materials?search=${this.searchValue}`
        );
      }

      return (
        axios
          /*
          .get(
          `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
          auth
          )
          */
          /* working one
	        `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
	        */
          .get(this.endPointCompute, auth)
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
            //const el = document.getElementById("pag");
            //el.scrollIntoView({ behavior: "smooth" });
          })
      );
    },

    showLightbox(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "preview");
        //this.lightboxImage = media;
      } else {
        //this.$store.commit("UPDATE_LIGHTBOX_IMAGE_FIELD_SERVICE", media);
        this.lightboxImage = media;
      }
    },
    showLightboxVideo(media) {
      let file = media;
      console.log(media);
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confvid");
        //this.lightboxVideo = file;
      } else {
        //this.$store.commit("UPDATE_LIGHTBOX_VIDEO_FIELD_SERVICE", media);
        this.lightboxVideo = media;
      }
    },
    showLightboxConf(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confdoc");
        //this.lightboxConfidential = media;
      } else {
        this.lightboxConfidential = media;
      }
      /*
			document.addEventListener('contextmenu', function(event) {
			event.preventDefault();
			}, true); 
			*/
    },

    closeLightbox() {
      //this.$store.commit("CLEAR_LIGHTBOX_IMAGE_FIELD_SERVICE", "");
      //this.$store.commit("CLEAR_LIGHTBOX_VIDEO_FIELD_SERVICE", "");
      //this.$store.commit("CLEAR_LIGHTBOX_CONF_FIELD_SERVICE", "");
      this.lightboxImage = "";
      this.lightboxVideo = "";
      this.lightboxConfidential = "";
      //document.removeEventListener("contextmenu");
    },

    isActiveButton: function (text) {
      return text;
    },

    displayFiles() {
      this.showFiles = !this.showFiles;
    },
    displayMarkets() {
      this.showMarkets = !this.showMarkets;
    },
    displayProducts() {
      this.showProducts = !this.showProducts;
    },
    displayMobile() {
      this.showMobile = !this.showMobile;
    },
    displayShelving() {
      this.showShelving = !this.showShelving;
    },
    displayStorage() {
      this.showStorage = !this.showStorage;
    },
    displayPowered() {
      this.showPowered = !this.showPowered;
    },
    displayMechassist() {
      this.showMechassist = !this.showMechassist;
    },
    displayManual() {
      this.showManual = !this.showManual;
    },
    displayArtRacks() {
      this.showArtRacks = !this.showArtRacks;
    },
    displayTypes() {
      this.showTypes = !this.showTypes;
    },

    /*
    showCats() {
      // https://api.widencollective.com/v2/categories/{category}/{subcategory}/{...}
    }
    */
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    shouldButtonBeActive: function () {
      return this.markets.filter((el) => this.isActiveButton(el)).length === 0;
    },

    myOffsetVal() {
      return this.offsetVal.valueOf();
    },

    endPointCompute() {
      //return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AMarket%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
      // this one return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.search}${this.catType}&limit=100&&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
      if (!this.searchValue.length) {
        // search is the button click value, this is if the search bar isn't filled in
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.search}${this.catType}&limit=100&&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
      } else {
        // search value is the typed value, this is if the search bar is filled in
        //return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}%20cat%3A${this.search}${this.catType}&limit=100&&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
      }
    },

    cart() {
      return this.$store.state.cart;
    },

    currentMarket() {
      return this.$store.state.market;
    },

    markets() {
      return this.$store.state.markets;
    },

    fileTypes() {
      return this.$store.state.fileTypes;
    },

    products() {
      return this.$store.state.products;
    },
    showCopy() {
      return this.$store.state.eulaAccepted;
    },
    /*
    checksortPref(){
      if (this.listView) {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "list");
      } else {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "grid");
      }
    }
    */
  },
  created() {
    this.$store.commit("showNavigation");
    this.showEula = Eula.showEula; // now you can call this.showEula() (in your functions/template)
    this.triggerLightbox = Eula.triggerLightbox;
    /*
    if (this.$route.params.slug) {
      history.pushState({}, '', `/pagepath/path?query=${this.myQueryParam}`);
      this.this.pageSlug = this.$route.params.slug;
    }
    */
    // console.log(this.$route.path);
  },

  mounted() {
    // check the file type of the query
    if (this.$route.query.file === "document") {
      this.fileType =
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    } else if (this.$route.query.file == "image") {
      this.fileType = "JPEG";
    } else if (this.$route.query.file == "video") {
      this.fileType = "MPEG4";
    } else {
      this.fileType =
        "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    }

    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.docwrap {
  background-repeat: no-repeat;
  background-position: center center;
}
.docwrap img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.card {
  margin-bottom: 15px;
}
.word-wrap {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadImage a {
  color: #fff;
  transition: color 0.5s ease;
}
.downloadImage a:hover {
  color: #42b983;
}
.mr5 {
  margin-right: 5px;
}
.img-responsive-4by3 {
  display: block;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}
.img-responsive-4by3 img {
  width: 100%;
}
.img-responsive-16by9 {
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.img-responsive-16by9 img {
  width: 100%;
}
span.file-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}
.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent #000000 transparent transparent;
  line-height: 0px;
  _border-color: #ffffff #000000 #ffffff #ffffff;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
}
.flexwrap {
  margin-bottom: 0;
}

.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
  height: 40px;
}

.inactive-tri {
  /*transition: transform 0.2s ease-in-out;*/
  transition: transform 0.4s ease-in-out;
  transform-style: preserve-3d;
}
.active-tri {
  transition: transform 0.4s ease-in-out;
  display: flex;
  align-items: flex-end;
  align-items: flex-end;
  /*transform: rotate(180deg);*/
  transform: rotateX(180deg);
}
.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
.badge-orange {
  background-color: #00b4b4;
  background-color: #3e4651;
  background-color: #f16232;
  color: #fff;
  font-size: 0.7em !important;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}
.mb5 {
  margin-bottom: 5px !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-box {
  margin-left: auto;
  margin-right: 30px;
  background: #4d5967;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 4em;
}
.right-side {
  text-align: right;
  flex: 1;
}
.links:hover,
.links:active {
  text-decoration: none !important;
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.software {
  align-items: center;
  justify-content: center;
}
.software .material-icons {
  font-size: 1.2em !important;
}

.softwareli {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin: 0 0 5px;
}

.software-link a {
    color: #429da8;
    cursor: pointer;
}
</style>
